const $ = require('jquery')

$(function () {
  if (window.devicePixelRatio === 2) {
    $('img.retina-me').each(function (idx, elem) {
      var $image = $(elem);
      var src = $image.attr('src');
      var srcParts = src.split('.');
      var ext = srcParts[srcParts.length - 1];
      var newSrc = srcParts.slice(0, -1).join('.') + '@2x.' + ext;
      var oldWidth = $image.width();
      $image.attr('src', newSrc);

      if ($image.hasClass('retina-me-autowidth')) {
        $image.width(oldWidth);
      }
    });
  }
});
